export const options = [
    { id: 1, title: "Transfer", subtitle: "Balance transfer from the card" },
    { id: 2, title: "Water Payment", subtitle: "Pay water bill" },
    { id: 3, title: "Electricity Payment", subtitle: "Pay electricity bill" },
    { id: 4, title: "Mobile Recharge", subtitle: "Recharge mobile balance with bank account" },
    { id: 5, title: "Telephone", subtitle: "Pay telephone bill" },
    { id: 6, title: "Nauta Recharge", subtitle: "Recharge permanent Nauta account" },
    { id: 7, title: "Onat", subtitle: "Pay Onat" },
    { id: 8, title: "Traffic Fine Payment", subtitle: "Pay traffic fine" },
    { id: 9, title: "Gas Payment", subtitle: "Pay manufactured gas bill" },
    { id: 10, title: "Online Payment", subtitle: "E-commerce payment" },
];
