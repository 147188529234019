import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import _ from "i18n/translate";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import fincimexIsotopo from "assets/images/logos/fincimex(Identificador).svg";
import { options } from "./data";
import { ArrowBack, Clear } from "@mui/icons-material";
import GeneralForm from "./components/GeneralForm";
import OnatOptionsModal from "components/MDModal/OnatOptionsModal";

const Operations = ({ open, onClose }) => {
    const [selectOperation, setSelectOperation] = useState(null);
    const [operationId, setOperationId] = useState(0);
    const [openOnatOptionsModal, setOpenOnatOptionsModal] = useState(false);

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: {
                        xs: "95dvw",
                        xl: selectOperation ? "80dvw" : "70dvw",
                        xxl: selectOperation ? "80dvw" : "45dvw",
                    },
                    p: { xs: 1, md: 3 },
                    borderRadius: 2,
                    border: "none",
                    bgcolor: "#fff",
                    transition: "width .5s ease",
                }}
            >
                <MDBox
                    mx={2}
                    mt={-7}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: { xs: "80%" },
                        mx: "auto",
                    }}
                >
                    <Box
                        component="img"
                        src={fincimexIsotopo}
                        alt=""
                        sx={{ height: { xs: 25, md: 35 } }}
                    />
                    <MDTypography color="light" textAlign="center" sx={{ fontSize: ".8rem" }}>
                        {_("Registration date: September 20, 2024")}
                    </MDTypography>
                </MDBox>
                <IconButton
                    onClick={() => setSelectOperation(null)}
                    sx={{
                        display: { xs: selectOperation ? "flex" : "none", lg: "none" },
                        position: "absolute",
                        top: { xs: 4, md: 10 },
                        left: 3,
                    }}
                >
                    <ArrowBack />
                </IconButton>
                <IconButton
                    onClick={() => onClose()}
                    sx={{
                        position: "absolute",
                        top: { xs: 4, md: 10 },
                        right: 3,
                    }}
                >
                    <Clear />
                </IconButton>

                <Box
                    sx={{
                        position: "relative",
                        display: "flex",
                        mt: 3,
                        p: 1,
                        borderRadius: 2,
                        maxHeight: 600,
                        overflow: "hidden",
                    }}
                >
                    <Box
                        sx={{
                            width: { xs: "100%", lg: selectOperation ? "65%" : "100%" },
                            display: "flex",
                            flexWrap: "wrap",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            overflowY: "auto",
                            gap: 2,
                            p: 0.5,
                            transition: "width 0.5s ease",
                        }}
                    >
                        {options.map((option) => (
                            <MDBox
                                key={option.id}
                                onClick={() => {
                                    option.id === 7
                                        ? setOpenOnatOptionsModal(true)
                                        : setSelectOperation(option);
                                    setOperationId(option.id);
                                }}
                                variant={operationId === option.id ? "gradient" : "contained"}
                                bgColor={operationId === option.id ? "info" : "#fff"}
                                sx={{
                                    width: { xs: "100%", lg: "47%" },
                                    borderRadius: 2,
                                    boxShadow: 2,
                                    px: 2,
                                    py: 1,
                                    cursor: "pointer",
                                    "&:hover": {
                                        boxShadow: 4,
                                    },
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: 600,
                                        color:
                                            operationId === option.id ? "#fff" : "rgb(100,100,100)",
                                    }}
                                >
                                    {option.title}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: ".8rem",
                                        color: "gray",
                                        mt: -0.5,
                                        color: operationId === option.id ? "#fff" : "gray",
                                    }}
                                >
                                    {option.subtitle}
                                </Typography>
                            </MDBox>
                        ))}
                    </Box>

                    <Box
                        sx={{
                            position: "absolute",
                            top: 0,
                            right: selectOperation ? 0 : "-100%",
                            width: { xs: "100%", lg: "35%" },
                            height: "100%",
                            bgcolor: "#fff",
                            p: 2,
                            transition: "all 0.5s ease",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <GeneralForm
                            selected={selectOperation?.id - 1 || 0}
                            onCancel={setSelectOperation}
                        />
                    </Box>
                </Box>
                <OnatOptionsModal
                    open={openOnatOptionsModal}
                    onClose={() => setOpenOnatOptionsModal(false)}
                    changeOption={setSelectOperation}
                />
            </Box>
        </Modal>
    );
};

Operations.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default Operations;
