import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { Formik } from "formik";
import * as Yup from "yup";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import _ from "i18n/translate";
import { fieldsData } from "./data";
import CustomTextField from "components/MDInput/CustomTextField";
import useFieldFormik from "hooks/useFieldFormik";

const FieldComponent = ({ field, formik }) => {
    const fieldProps = useFieldFormik(formik, field.id);

    return (
        <CustomTextField
            key={field.id}
            id={field.id}
            label={field.label}
            formik={fieldProps}
            type={field.type}
            select={field.select}
            options={field.options}
            number={field.number}
            bool={field.bool}
            sx={{ mb: 1 }}
        />
    );
};

FieldComponent.propTypes = {
    field: PropTypes.object.isRequired,
    formik: PropTypes.object.isRequired,
};

const GeneralForm = ({ selected, onCancel }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [fields, setFields] = useState([]);

    useEffect(() => {
        if (fieldsData[selected]) {
            setFields(fieldsData[selected]);
        }
    }, [selected]);

    if (!fields?.data?.length) return null;

    return (
        <Box
            sx={{
                width: "100%",
                overflowY: "auto",
                px: 2,
                pb: 2,
            }}
        >
            <MDTypography variant="h6" color="dark" textAlign="center">
                {_(fields.title)}
            </MDTypography>
            <Formik
                initialValues={fields.data.reduce((values, field) => {
                    values[field.id] = field.bool ? false : "";
                    return values;
                }, {})}
                validationSchema={Yup.object(
                    fields.data.reduce((schema, field) => {
                        schema[field.id] = field.validation;
                        return schema;
                    }, {})
                )}
                onSubmit={async (values) => {
                    try {
                        enqueueSnackbar(_("Mobile recharge initiated successfully"), {
                            variant: "success",
                        });
                        onCancel();
                    } catch (error) {
                        enqueueSnackbar(_("Error initiating mobile recharge"), {
                            variant: "error",
                        });
                    }
                }}
            >
                {(formik) => (
                    <Box component="form" onSubmit={formik.handleSubmit}>
                        <MDBox my={3}>
                            {fields.data.map((field) => {
                                const shouldDisplayField = field.condition
                                    ? field.condition.value.includes(
                                          formik.values[field.condition.field]
                                      )
                                    : true;

                                return shouldDisplayField ? (
                                    <FieldComponent key={field.id} field={field} formik={formik} />
                                ) : null;
                            })}
                        </MDBox>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginTop: 2,
                            }}
                        >
                            <Button
                                variant="outlined"
                                onClick={() => onCancel(null)}
                                fullWidth
                                sx={{
                                    color: "#348DED",
                                    "&:hover": { border: "1px solid #348DED" },
                                }}
                            >
                                {_("Close")}
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                fullWidth
                                disabled={formik.isSubmitting}
                                sx={{ bgcolor: "info", color: "#fff", ml: 2 }}
                            >
                                {formik.isSubmitting ? _("Processing...") : _("Pay")}
                            </Button>
                        </Box>
                    </Box>
                )}
            </Formik>
        </Box>
    );
};

GeneralForm.propTypes = {
    selected: PropTypes.number.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default GeneralForm;
