import { Box, Card, Stack, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import _ from "i18n/translate";
import React from "react";
import data from "./data";

const ContractedService = () => {
    return (
        <Card>
            <MDBox pt={3} px={3}>
                <MDTypography variant="h6" fontWeight="medium">
                    {_("Contracted payment service")}
                </MDTypography>
            </MDBox>
            <MDBox p={2}>
                <Stack flexDirection="row" gap={3} px={1}>
                    <Box component="img" src={data.logo} alt="" sx={{ height: 30 }} />
                    <Typography sx={{ fontSize: "1rem", fontWeight: 600 }}>
                        {_(data.serviceName)}
                    </Typography>
                </Stack>
                <Typography sx={{ fontSize: ".9rem", px: 1, mt: 2 }}>
                    {_("Registration date: ")}
                    {_(data.registrationDate)}
                </Typography>
            </MDBox>
        </Card>
    );
};

export default ContractedService;
