export const masterCards = [
    {
        number: "4562129945947852",
        holder: "Jack Peterson",
        expires: "11/22",
    },
    {
        number: "5213069989012345",
        holder: "Emily Johnson",
        expires: "04/25",
    },
    {
        number: "3759959832101235",
        holder: "Michael Smith",
        expires: "08/23",
    },
    {
        number: "6011959856789123",
        holder: "Sophia Williams",
        expires: "09/24",
    },
    {
        number: "4023129956789012",
        holder: "David Brown",
        expires: "12/23",
    },
];
