/**
=========================================================
* Material Dashboard 2  React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// DefaultLineChart configurations
import configs from "examples/Charts/LineCharts/DefaultLineChart/configs";

// Material Dashboard 2 React base styles
import colors from "assets/theme/base/colors";
import { Box } from "@mui/material";
import _ from "i18n/translate";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

function DefaultLineChart({ icon, title, description, height, chart }) {
    const chartDatasets = chart.datasets
        ? chart.datasets.map((dataset) => ({
              ...dataset,
              tension: 0,
              pointRadius: 3,
              borderWidth: 4,
              backgroundColor: "transparent",
              fill: true,
              pointBackgroundColor: colors[dataset.color]
                  ? colors[dataset.color || "dark"].main
                  : colors.dark.main,
              borderColor: colors[dataset.color]
                  ? colors[dataset.color || "dark"].main
                  : colors.dark.main,
              maxBarThickness: 6,
          }))
        : [];

    const { data, options } = configs(chart.labels || [], chartDatasets);

    // Método para renderizar la leyenda
    const renderLegend = () => (
        <MDBox
            display="flex"
            justifyContent="center"
            mt={1}
            alignItems="center"
            sx={{ flexWrap: "wrap", gap: 2 }}
        >
            {chart.datasets.map((dataset) => (
                <MDBox
                    key={dataset.label}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ flexWrap: "wrap" }}
                >
                    <MDBox
                        width="2rem"
                        height="1rem"
                        borderRadius="4px"
                        sx={{
                            bgcolor: colors[dataset.color]
                                ? colors[dataset.color || "dark"].main
                                : colors.dark.main,
                            mr: 0.5,
                        }}
                    />
                    <MDTypography variant="body2">{dataset.label}</MDTypography>
                </MDBox>
            ))}
        </MDBox>
    );

    const renderChart = (
        <MDBox py={2} pr={2} pl={icon.component ? 1 : 2} sx={{ position: "relative" }}>
            <Box
                sx={{
                    position: "absolute",
                    content: '""',
                    bottom: -50,
                    width: "105%",
                    height: "80%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "end",
                    p: 1,
                    ml: -3.5,
                    background: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                    borderRadius: 5,
                    boxShadow: 8,
                    zIndex: -1,
                }}
            >
                {title && (
                    <MDTypography variant="h6" color="white">
                        {_(title)}
                    </MDTypography>
                )}
            </Box>
            {title || description ? (
                <MDBox display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
                    {icon.component && (
                        <MDBox
                            width="4rem"
                            height="4rem"
                            bgColor={icon.color || "dark"}
                            variant="gradient"
                            coloredShadow={icon.color || "dark"}
                            borderRadius="xl"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            color="white"
                            mt={-5}
                            mr={2}
                        >
                            <Icon fontSize="medium">{icon.component}</Icon>
                        </MDBox>
                    )}
                    <MDBox mt={icon.component ? -2 : 0}>
                        <MDBox mb={2}>
                            <MDTypography component="div" variant="button" color="text">
                                {_(description)}
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </MDBox>
            ) : null}
            {useMemo(
                () => (
                    <MDBox height={height}>
                        <Line data={data} options={options} redraw />
                    </MDBox>
                ),
                [chart, height]
            )}
            {renderLegend()}
        </MDBox>
    );

    return title || description ? <Card>{renderChart}</Card> : renderChart;
}

// Setting default values for the props of DefaultLineChart
DefaultLineChart.defaultProps = {
    icon: { color: "info", component: "" },
    title: "",
    description: "",
    height: "19.125rem",
};

// Typechecking props for the DefaultLineChart
DefaultLineChart.propTypes = {
    icon: PropTypes.shape({
        color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "light",
            "dark",
        ]),
        component: PropTypes.node,
    }),
    title: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    chart: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default DefaultLineChart;
