import PropTypes from "prop-types";
import { Menu, IconButton, Box } from "@mui/material";
import NotificationItem from "examples/Items/NotificationItem";
import Icon from "@mui/material/Icon";
import { useState } from "react";

const NotificationMenu = ({ items, onNotificationClick }) => {
    const [openMenu, setOpenMenu] = useState(null);

    return (
        <Box sx={{ position: "absolute", right: 10, top: 10 }}>
            <IconButton
                size="small"
                disableRipple
                color="inherit"
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={(event) => setOpenMenu(event.currentTarget)}
                sx={{ fontSize: "1.5rem" }}
            >
                <Icon>more_vert</Icon>
            </IconButton>
            <Menu
                id="notification-menu"
                anchorEl={openMenu}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={Boolean(openMenu)}
                onClose={() => setOpenMenu(null)}
                sx={{ mt: 2 }}
            >
                {items.map((item, index) => (
                    <NotificationItem
                        key={index}
                        icon={item.icon && <Icon>{item.icon}</Icon>}
                        title={item.title}
                        onClick={() => {
                            setOpenMenu(null);
                            onNotificationClick(item);
                        }}
                    />
                ))}
            </Menu>
        </Box>
    );
};

// Validaciones de PropTypes
NotificationMenu.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            icon: PropTypes.string,
        })
    ).isRequired,
    onNotificationClick: PropTypes.func.isRequired,
};

export default NotificationMenu;
