export const formatCardNumberHidden = (cardNumber) => {
    return cardNumber
        .replace(/\D/g, "")
        .replace(/^(.{4})(.{8})(.*)$/, "$1XXXXXXXX$3")
        .replace(/(.{4})/g, "$1-")
        .slice(0, 19)
        .replace(/-$/, "");
};
export const formatCardNumberVisible = (cardNumber) => {
    return cardNumber
        .replace(/\D/g, "")
        .replace(/(.{4})/g, "$1-")
        .slice(0, 19)
        .replace(/-$/, "");
};
export const removeCardNumberFormatting = (number) => {
    return number.replace(/\D/g, "").slice(0, 19).replace(/-$/, "");
};
