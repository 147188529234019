import { useEffect, useState } from "react";
import transfermovilImage from "assets/images/logos/transfermovil.svg";
import enzonaImage from "assets/images/logos/enzona.svg";
import fincimexImage from "assets/images/logos/fincimex.svg";
import { formatCardNumberHidden } from "hooks/formatCardNumber";

export const useBillingData = (rows) => {
    const [dataRows, setDataRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [openPaymentDetailsModal, setOpenPaymentDetailsModal] = useState(false);

    const getServiceImage = (service) => {
        switch (service) {
            case "Enzona":
                return enzonaImage;
            case "Transfermovil":
                return transfermovilImage;
            case "Fincimex":
                return fincimexImage;
            default:
                return null;
        }
    };

    const formatAmount = (amount) => {
        const isPositive = amount > 0;
        return {
            formattedAmount: `${isPositive ? "+" : "-"} $${Math.abs(amount)}`,
            amountColor: isPositive ? "green" : "red",
        };
    };

    useEffect(() => {
        setDataRows(
            rows.map((row) => ({
                ...row,
                service: (
                    <img
                        src={getServiceImage(row.service)}
                        alt={row.service}
                        style={{ height: 30, maxWidth: 70 }}
                    />
                ),
                amount: (
                    <span style={{ color: formatAmount(row.amount).amountColor }}>
                        {formatAmount(row.amount).formattedAmount}
                    </span>
                ),
            }))
        );
    }, []);

    useEffect(() => {
        if (selectedRow !== null) {
            setSelectedRow((selectedRow) => {
                const selectedRowData = rows.find(
                    (row) => row.payment_code === selectedRow?.payment_code
                );
                return {
                    ...selectedRowData,
                    origin_card: formatCardNumberHidden(selectedRowData.origin_card),
                    destination_card: formatCardNumberHidden(selectedRowData.destination_card),
                };
            });
            setOpenPaymentDetailsModal(true);
        }
    }, [selectedRow]);

    const handleRowSelect = (row) => setSelectedRow(row);
    const handleModalClose = () => {
        setOpenPaymentDetailsModal(false);
        setSelectedRow(null);
    };

    return {
        dataRows,
        selectedRow,
        openPaymentDetailsModal,
        handleRowSelect,
        handleModalClose,
    };
};
