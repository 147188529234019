/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Data
import { chartData, chartData2 } from "./data/reportsLineChartDataTransfermovilEnzona";
import { statisticsData } from "./data/statisticsData";

// Dashboard components
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { Card, Typography } from "@mui/material";
import Transactions from "./components/Transactions";

import _ from "i18n/translate";

import { useSecurity } from "security";
import { useEffect } from "react";
import ContractedService from "./components/ContractedService";

function Dashboard() {
    // const { sales, tasks } = reportsLineChartData;
    const { bearerToken } = useSecurity();
    useEffect(() => {
        console.log(bearerToken);
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox py={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6} sx={{ mb: 1.5 }}>
                        <Card
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                height: "100%",
                                p: 3,
                            }}
                        >
                            <Typography variant="h6">{'MIPYME "LOS EMPRENDEDORES"'}</Typography>
                            <Typography sx={{ fontSize: { xs: ".9rem", md: "1rem" } }}>
                                NIT 123456789
                            </Typography>
                            <Typography sx={{ fontSize: { xs: ".8rem", md: ".9rem" } }}>
                                Calle 5ta #120 e/2da y 4ta, Los Pinos. Marianao. La Habana
                            </Typography>
                        </Card>
                    </Grid>

                    {statisticsData?.map((stat, index) => (
                        <Grid item xs={12} md={6} lg={3} key={index}>
                            <MDBox mb={1.5}>
                                <DefaultInfoCard
                                    icon={stat.icon}
                                    title={stat.title}
                                    value={stat.count}
                                />
                            </MDBox>
                        </Grid>
                    ))}
                </Grid>
                <MDBox mt={4.5}>
                    <Grid container spacing={6}>
                        <Grid item xs={12} md={6} lg={6} mb={7}>
                            <MDBox mb={3}>
                                <DefaultLineChart
                                    color="info"
                                    title={_("Distribution of payment methods")}
                                    chart={chartData}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} mb={7}>
                            <MDBox mb={3}>
                                <DefaultLineChart
                                    color="success"
                                    title={_("Successful vs. failed transactions")}
                                    chart={chartData2}
                                />
                            </MDBox>
                        </Grid>
                    </Grid>
                </MDBox>
                <MDBox>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={8}>
                            <Transactions />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <OrdersOverview />
                            <ContractedService />
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Dashboard;
