/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function DefaultInfoCard({ color, icon, title, description, value, big }) {
    return (
        <Card sx={{ height: big ? "100%" : "auto" }}>
            <MDBox p={2} mx={3} display="flex" justifyContent="center">
                <MDBox
                    variant="gradient"
                    bgColor={color}
                    color={color === "light" ? "dark" : "white"}
                    coloredShadow={color}
                    borderRadius="xl"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="4rem"
                    height="4rem"
                    mt={-4}
                    p={2}
                >
                    {icon}
                </MDBox>
            </MDBox>
            <MDBox pt={big ? 2 : 0} pb={2} px={2} textAlign="center" lineHeight={1.25}>
                <MDTypography
                    variant="h6"
                    fontWeight="medium"
                    textTransform="capitalize"
                    sx={{ fontSize: big ? "1.2rem" : "1rem" }}
                >
                    {title}
                </MDTypography>
                {description && (
                    <MDTypography variant="caption" color="text" fontWeight="regular">
                        {description}
                    </MDTypography>
                )}
                {description && !value ? null : <Divider />}
                {value && (
                    <MDTypography
                        variant="h6"
                        fontWeight="medium"
                        sx={{ fontSize: big ? "1.2rem" : "1rem" }}
                    >
                        {value}
                    </MDTypography>
                )}
            </MDBox>
        </Card>
    );
}

// Setting default values for the props of DefaultInfoCard
DefaultInfoCard.defaultProps = {
    color: "info",
    value: "",
    description: "",
    big: false,
};

// Typechecking props for the DefaultInfoCard
DefaultInfoCard.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    big: PropTypes.bool,
};

export default DefaultInfoCard;
