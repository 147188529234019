import { graphqlRoute } from "consts";

async function graphqlBase({ query, isPrivate = false }) {
    const headers = {
        "Content-Type": "application/json",
    };
    if (isPrivate) {
        headers.Authorization = `Bearer ${sessionStorage.getItem("bearerToken")}`;
    }
    let res = await fetch(graphqlRoute, {
        headers: headers,
        method: "post",
        body: JSON.stringify({
            query: query,
            variables: {},
        }),
    });
    if (res.ok) {
        res = await res.json();
        return res.data;
    }
}
export async function isTokenAliveQuery() {
    const res = await graphqlBase({
        query: `
        query AliveTest {
            protected
        }
    `,
        isPrivate: true,
    });
    if (res.protected) {
        return true;
    } else {
        return false;
    }
}
export async function authMutation({ code }) {
    return graphqlBase({
        query: `
                    mutation Authenticate{
                        login(code: "${code}", codeVerifier: "${localStorage.getItem(
            "pkce_code_verifier"
        )}"){
                            bearerToken
                        }
                    }
                `,
    });
}
