import React from "react";
import PropTypes from "prop-types";
import { Modal, Box, Stack, Typography } from "@mui/material";
import _ from "i18n/translate";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { formatCardNumberVisible } from "hooks/formatCardNumber";
import MDButton from "components/MDButton";

const SelectMasterCardModal = ({ open, onClose, masterCards, changeCard }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: { xs: "95%", md: 500 },
                    p: 3,
                    borderRadius: 2,
                    border: "none",
                    bgcolor: "#fff",
                }}
            >
                <MDBox
                    mx={2}
                    mt={-7}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                >
                    <MDTypography variant="h6" color="white" textAlign="center">
                        {_("Select a card")}
                    </MDTypography>
                </MDBox>

                <Box sx={{ mt: 3, p: 1, borderRadius: 2, maxHeight: 500, overflow: "auto" }}>
                    {masterCards.map((card, index) => (
                        <Stack
                            key={index}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                            onClick={() => {
                                onClose();
                                changeCard(card);
                            }}
                            sx={{
                                position: "relative",
                                overflow: "hidden",
                                borderRadius: 2,
                                p: 2,
                                mb: 2.5,
                                boxShadow: 3,
                                cursor: "pointer",
                                "&:hover": {
                                    boxShadow: 5,
                                    color: "#fff",
                                },
                                "&:after": {
                                    position: "absolute",
                                    content: '""',
                                    height: "100%",
                                    width: { xs: "70%", md: "55%" },
                                    right: -10,
                                    top: 0,
                                    background: "linear-gradient(90deg, #54a1ff, #237cea)",
                                    clipPath: "polygon(10% 100%, 100% 100%, 100% 0, 10% 0, 0 50%)",
                                    zIndex: 1,
                                    transition: "width 0.3s ease",
                                },
                                "&:hover:after": {
                                    width: "120%",
                                },
                            }}
                        >
                            <Typography variant="h6" sx={{ zIndex: 2, color: "inherit" }}>
                                {card.holder}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: { xs: ".9rem", md: "1rem", zIndex: 2, color: "#fff" },
                                }}
                            >
                                {formatCardNumberVisible(card.number)}
                            </Typography>
                        </Stack>
                    ))}
                </Box>

                <MDButton
                    variant="outlined"
                    fullWidth
                    onClick={onClose}
                    sx={{
                        color: "#348DED",
                        "&:hover": { border: "1px solid #348DED" },
                    }}
                >
                    {_("Cancel")}
                </MDButton>
            </Box>
        </Modal>
    );
};

SelectMasterCardModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    masterCards: PropTypes.array.isRequired,
    changeCard: PropTypes.func.isRequired,
};

export default SelectMasterCardModal;
