import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import TimelineItem from "examples/Timeline/TimelineItem";
import _ from "i18n/translate";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, IconButton, Typography } from "@mui/material";
import { useSnackbar } from "notistack";

function OrdersOverview({ clientId, clientSecret }) {
    const { enqueueSnackbar } = useSnackbar();

    const handleCopy = (key, text) => {
        navigator.clipboard.writeText(key).then(
            () => {
                enqueueSnackbar(_("Copied to clipboard: " + text), { variant: "success" });
            },
            (err) => {
                enqueueSnackbar(_("Failed to copy: " + err), { variant: "error" });
            }
        );
    };

    return (
        <>
            <MDBox pt={1}>
                <MDBox display="flex" alignItems="center" justifyContent="flex-start">
                    <TimelineItem
                        color="info"
                        icon="lock_open_outlined"
                        title="Client ID"
                        dateTime={clientId}
                        lastItem={!clientSecret}
                    />
                    <IconButton
                        onClick={() => handleCopy(clientId, "Client ID")}
                        aria-label="copy"
                        sx={{ mt: -2 }}
                    >
                        <ContentCopyIcon sx={{ height: 18 }} />
                    </IconButton>
                </MDBox>

                {clientSecret ? (
                    <MDBox display="flex" alignItems="center" justifyContent="flex-start">
                        <TimelineItem
                            color="info"
                            icon="lock_outlined"
                            title="Client Secret"
                            dateTime={clientSecret}
                            lastItem
                        />
                        <IconButton
                            onClick={() => handleCopy(clientSecret, "Client Secret")}
                            aria-label="copy"
                            sx={{ mt: -2 }}
                        >
                            <ContentCopyIcon sx={{ height: 18 }} />
                        </IconButton>
                    </MDBox>
                ) : null}
            </MDBox>
            <Box
                sx={{
                    backgroundImage: "linear-gradient(45deg, rgb(255, 0, 0), #ed4e4b)",
                    py: 1,
                    px: 2,
                    borderRadius: 2,
                    mt: -2,
                    mb: 2,
                    width: { xs: 260, sm: 400, md: "100%" },
                }}
            >
                <Typography
                    sx={{
                        fontSize: { xs: ".8rem", md: ".9rem" },
                        fontWeight: 500,
                        color: "#fff",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {_("You must copy the keys before exiting because they will be deleted")}
                </Typography>
            </Box>
        </>
    );
}

OrdersOverview.propTypes = {
    clientId: PropTypes.string,
    clientSecret: PropTypes.string,
};

export default OrdersOverview;
