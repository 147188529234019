/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import TimelineItem from "examples/Timeline/TimelineItem";

import data from "./data";

import _ from "i18n/translate";

function OrdersOverview() {
    const { rows } = data();

    return (
        <Card sx={{ mb: 2 }}>
            <MDBox pt={3} px={3}>
                <MDTypography variant="h6" fontWeight="medium">
                    {_("Businesses created")}
                </MDTypography>
                {/* <MDBox mt={0} mb={2}>
                    <MDTypography variant="button" color="text" fontWeight="regular">
                        <MDTypography display="inline" variant="body2" verticalAlign="middle">
                            <Icon sx={{ color: ({ palette: { success } }) => success.main }}>
                                arrow_upward
                            </Icon>
                        </MDTypography>
                        &nbsp;
                        <MDTypography variant="button" color="text" fontWeight="medium">
                            24%
                        </MDTypography>{" "}
                        this month
                    </MDTypography>
                </MDBox> */}
            </MDBox>
            <MDBox p={2}>
                {rows.map((transaction, index) => (
                    <TimelineItem
                        key={index}
                        color="info"
                        icon="store"
                        title={transaction.title}
                        dateTime={transaction.money}
                        lastItem={index === rows.length - 1}
                    />
                ))}
            </MDBox>
        </Card>
    );
}

export default OrdersOverview;
