import XPayIsotopo from "assets/images/logos/IsotipoWhite.svg";
import { AccountBalance, Cached } from "@mui/icons-material";
import { Box } from "@mui/material";
import _ from "i18n/translate";

export const statisticsData = [
    {
        icon: <AccountBalance sx={{ height: "100%", width: "100%" }} />,
        title: _("Total income"),
        count: "$ 15,000.00",
    },
    {
        icon: <Cached sx={{ height: "120%", width: "120%" }} />,
        title: _("Transactions"),
        count: "45 K",
    },
];
