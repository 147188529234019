export const options = [
    {
        id: 11,
        name: "Payment via tax vector (RC-04A)",
    },
    {
        id: 12,
        name: "Payment by NIT or identity card",
    },
    {
        id: 13,
        name: "Stamp seals",
    },
];
