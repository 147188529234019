export default function data() {
    return {
        rows: [
            {
                title: "Pizzeria 'The Queen'",
                money: "$500.00",
            },
            {
                title: "Mini-market 'The Corner'",
                money: "$500.00",
            },
        ],
    };
}
