import React, { useEffect, useState } from "react";
import {
    TextField,
    MenuItem,
    InputAdornment,
    IconButton,
    Typography,
    Switch,
    FormControlLabel,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PropTypes from "prop-types";
import _ from "i18n/translate";
import { formatCardNumberVisible } from "hooks/formatCardNumber";

const CustomTextField = ({
    id,
    label,
    formik,
    select = false,
    options = [],
    type = "text",
    showPassword = false,
    toggleShowPassword,
    width = "100%",
    adornment = false,
    number = false,
    bool = false,
    sx = {},
}) => {
    const [isSubmitting, setisSubmitting] = useState(false);
    useEffect(() => {
        if (formik.isSubmitting && !isSubmitting) setisSubmitting(formik.isSubmitting);
    }, [formik.isSubmitting]);
    return (
        <>
            {bool ? (
                <FormControlLabel
                    control={
                        <Switch
                            color="primary"
                            size="small"
                            checked={!!formik.value}
                            onChange={(event) => formik.setFieldValue(event.target.checked)}
                        />
                    }
                    label={label}
                    sx={{
                        mt: 1,
                        "MuiTypography-body1": {
                            fontSize: ".9rem",
                            fontWeight: 300,
                        },
                    }}
                />
            ) : (
                <TextField
                    fullWidth
                    variant="standard"
                    id={id}
                    name={id}
                    label={label}
                    value={
                        id.toLowerCase().includes("card") && !id.toLowerCase().includes("identity")
                            ? formatCardNumberVisible(formik.value)
                            : formik.value
                    }
                    onChange={
                        id.toLowerCase().includes("card") ||
                        id.toLowerCase().includes("phone") ||
                        number
                            ? (event) =>
                                  formik.setFieldValue(
                                      event.target.value.replace(/\D/g, "").slice(0, 16)
                                  )
                            : formik.handleChange
                    }
                    onBlur={formik.handleBlur}
                    type={showPassword ? "text" : type}
                    select={select}
                    helperText={
                        (formik.touched || isSubmitting) && formik.error ? formik.error : ""
                    }
                    InputProps={{
                        startAdornment: id.toLowerCase().includes("phone") && formik.value && (
                            <InputAdornment position="start">
                                <Typography sx={{ fontSize: ".9rem" }}>(+53)</Typography>
                            </InputAdornment>
                        ),
                        endAdornment: adornment && (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={toggleShowPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    InputLabelProps={{
                        shrink: type === "date" ? true : undefined,
                    }}
                    sx={{
                        width: { xs: "100%", sm: width },
                        mt: 1,
                        "& .MuiFormHelperText-root": {
                            color: "red",
                        },
                        "& .MuiSelect-select": {
                            height: "30px !important",
                            ml: 0,
                        },
                        ...sx,
                    }}
                >
                    {select && (
                        <MenuItem value="">
                            <em>{_("Select")}</em>
                        </MenuItem>
                    )}
                    {options?.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>
                    ))}
                </TextField>
            )}
        </>
    );
};

CustomTextField.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    formik: PropTypes.object.isRequired,
    select: PropTypes.bool,
    options: PropTypes.array,
    type: PropTypes.string,
    showPassword: PropTypes.bool,
    toggleShowPassword: PropTypes.func,
    width: PropTypes.string,
    adornment: PropTypes.bool,
    number: PropTypes.bool,
    bool: PropTypes.bool,
    sx: PropTypes.object,
};

export default CustomTextField;
