import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import _ from "i18n/translate";
import PropTypes from "prop-types";

const TransactionRow = ({ transaction }) => {
    const formatDate = (date) => {
        const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        };
        return new Intl.DateTimeFormat(navigator.language, options).format(new Date(date));
    };

    return (
        <Box sx={{ display: "flex", justifyContent: "space-between", my: 1.5 }}>
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: { xs: 40, md: 45 },
                        width: { xs: 40, md: 45 },
                        mr: { xs: 0.5, md: 2 },
                        border: `2px solid ${transaction.positive ? "green" : "red"}`,
                        borderRadius: "50%",
                        boxShadow: 3,
                    }}
                >
                    {transaction.positive ? (
                        <ExpandLess
                            sx={{
                                color: "green",
                                height: { xs: 20, md: 25 },
                                width: { xs: 20, md: 25 },
                            }}
                        />
                    ) : (
                        <ExpandMore
                            sx={{
                                color: "red",
                                height: { xs: 20, md: 25 },
                                width: { xs: 20, md: 25 },
                            }}
                        />
                    )}
                </Box>
                <Box>
                    <Typography sx={{ fontSize: { xs: ".8rem", md: ".9rem" }, fontWeight: 500 }}>
                        {_(transaction.action)}
                    </Typography>
                    <Typography sx={{ fontSize: { xs: ".7rem", md: ".75rem" } }}>
                        {formatDate(transaction.date)}
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "end",
                    ml: 1,
                }}
            >
                <Typography
                    color={transaction.positive ? "success.main" : "error.main"}
                    sx={{ fontSize: { xs: ".8rem", md: ".9rem" } }}
                >
                    {transaction.positive ? "+ " : "- "}${transaction.money}
                </Typography>
            </Box>
        </Box>
    );
};

TransactionRow.propTypes = {
    transaction: PropTypes.shape({
        positive: PropTypes.bool.isRequired,
        action: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        bank: PropTypes.string.isRequired,
        money: PropTypes.number.isRequired,
    }).isRequired,
};

export default TransactionRow;
