const useFieldFormik = (formik, field) => {
    const value = formik.values[field] || "";
    const error = formik.errors[field];
    const touched = formik.touched[field];
    const isSubmitting = formik.isSubmitting;

    const handleChange = (event) => {
        formik.handleChange(event);
    };

    const handleBlur = (event) => {
        formik.handleBlur(event);
    };

    const setFieldValue = (val) => {
        formik.setFieldValue(field, val);
    };

    return {
        value,
        error,
        touched,
        isSubmitting,
        handleChange,
        handleBlur,
        setFieldValue,
    };
};

export default useFieldFormik;
