import _ from "i18n/translate";
import * as Yup from "yup";

const generateArticleOptions = () => {
    const options = [];
    for (let i = 61; i <= 217; i++) {
        options.push({ id: `article_${i}`, name: `${i}` });
    }
    return options;
};

const provinces = [
    { id: "pinar_del_rio", name: _("Pinar del Río") },
    { id: "artemisa", name: _("Artemisa") },
    { id: "la_habana", name: _("La Habana") },
    { id: "mayabeque", name: _("Mayabeque") },
    { id: "matanzas", name: _("Matanzas") },
    { id: "cienfuegos", name: _("Cienfuegos") },
    { id: "villa_clara", name: _("Villa Clara") },
    { id: "sancti_spiritus", name: _("Sancti Spíritus") },
    { id: "ciego_de_avila", name: _("Ciego de Ávila") },
    { id: "camaguey", name: _("Camagüey") },
    { id: "las_tunas", name: _("Las Tunas") },
    { id: "holguin", name: _("Holguín") },
    { id: "granma", name: _("Granma") },
    { id: "santiago_de_cuba", name: _("Santiago de Cuba") },
    { id: "guantanamo", name: _("Guantánamo") },
    { id: "isla_de_la_juventud", name: _("Isla de la Juventud") },
];

export const fieldsData = [
    {
        title: "Transfer",
        data: [
            {
                id: "recipientCardOrAccount",
                label: _("Recipient Card or Account"),
                validation: Yup.string()
                    .matches(
                        /^(03|05|06|12)\d{14}$|^\d{4}(?:1299|9598|0699)\d{8}$/,
                        _("It is NOT a valid account or card.")
                    )
                    .required(_("Recipient card or account is required")),
            },
            {
                id: "transferAmount",
                label: _("Transfer Amount"),
                number: true,
                validation: Yup.number()
                    .min(1, _("Amount must be greater than 0"))
                    .required(_("Transfer amount is required")),
            },
            {
                id: "confirmationPhone",
                label: _("Confirmation Phone"),
                validation: Yup.string()
                    .required(_("Confirmation phone number is required"))
                    .matches(/^(\+\d{1,3})?\s?\d{7,14}$/, _("Phone number is not valid")),
            },
        ],
    },
    {
        title: "Water Payment",
        data: [
            {
                id: "serviceIdentifier",
                label: _("Service Identifier"),
                validation: Yup.string().required(_("Service identifier is required")),
            },
        ],
    },
    {
        title: "Electricity Payment",
        data: [
            {
                id: "region",
                label: _("Region"),
                select: true,
                options: [
                    { id: "havanaWaters", name: _("Havana waters") },
                    { id: "restCountry", name: _("Rest of the Country") },
                ],
                validation: Yup.string().required(_("Region is required")),
            },
            {
                id: "cashierId",
                label: _("Cashier ID"),
                validation: Yup.string().required(_("Cashier ID is required")),
            },
        ],
    },
    {
        title: "Mobile Recharge",
        data: [
            {
                id: "transferAmount",
                label: _("Transfer Amount"),
                number: true,
                validation: Yup.number()
                    .min(1, _("Amount must be greater than 0"))
                    .required(_("Transfer amount is required")),
            },
            {
                id: "confirmationPhone",
                label: _("Confirmation Phone"),
                validation: Yup.string()
                    .required(_("Confirmation phone number is required"))
                    .matches(/^(\+\d{1,3})?\s?\d{7,14}$/, _("Phone number is not valid")),
            },
        ],
    },
    {
        title: "Telephone",
        data: [
            {
                id: "serviceIdentifier",
                label: _("Service Identifier"),
                validation: Yup.string().required(_("Service identifier is required")),
            },
            {
                id: "partialPayment",
                label: _("Partial Payment"),
                bool: true,
                validation: Yup.bool().notRequired(),
            },
            {
                id: "amountToPay",
                label: _("Amount to Pay"),
                number: true,
                condition: { field: "partialPayment", value: [true] },
                validation: Yup.number().when("partialPayment", {
                    is: (partialPayment) => partialPayment,
                    then: () => Yup.number().required(_("Amount to pay is required")),
                    otherwise: () => Yup.number().notRequired(),
                }),
            },
        ],
    },
    {
        title: "Nauta Account",
        data: [
            {
                id: "username",
                label: _("Username"),
                validation: Yup.string().required(_("Username is required")),
            },
            {
                id: "accountType",
                label: _("Account Type"),
                select: true,
                options: [
                    { id: "internationalNavigation", name: _("International Navigation") },
                    { id: "nationalNavigation", name: _("National Navigation") },
                ],
                validation: Yup.string().required(_("Account type is required")),
            },
            {
                id: "amountInCup",
                label: _("Amount in CUP"),
                number: true,
                validation: Yup.number()
                    .min(1, _("Amount must be greater than 0"))
                    .required(_("Amount in CUP is required")),
            },
            {
                id: "payNautaHome",
                label: _("Pay Nauta Home?"),
                bool: true,
                validation: Yup.bool().notRequired(),
            },
            {
                id: "payDebts",
                label: _("Pay Debts?"),
                bool: true,
                condition: { field: "payNautaHome", value: [true] },
                validation: Yup.bool().when("payNautaHome", {
                    is: true,
                    then: () => Yup.bool().required(_("Pay debts selection is required")),
                    otherwise: () => Yup.bool().notRequired(),
                }),
            },
            {
                id: "confirmationPhone",
                label: _("Confirmation Phone"),
                validation: Yup.string()
                    .required(_("Confirmation phone number is required"))
                    .matches(/^(\+\d{1,3})?\s?\d{7,14}$/, _("Phone number is not valid")),
            },
        ],
    },
    {
        title: "",
        data: [],
    },
    {
        title: "Traffic Fine Payment",
        data: [
            {
                id: "fineId",
                label: _("Fine ID"),
                validation: Yup.string().required(_("Fine ID is required")),
            },
            {
                id: "article",
                label: _("Article"),
                select: true,
                options: generateArticleOptions(),
                validation: Yup.string().required(_("Article is required")),
            },
            {
                id: "clause",
                label: _("Clause"),
                validation: Yup.string().required(_("Clause is required")),
            },
            {
                id: "editArticleClause",
                label: _("Edit Article and Clause?"),
                bool: true,
                validation: Yup.bool().notRequired(),
            },
            {
                id: "provinceOfLegalResidence",
                label: _("Province of Legal Residence"),
                select: true,
                options: provinces,
                validation: Yup.string().required(_("Province of legal residence is required")),
            },
            {
                id: "municipalityOfLegalResidence",
                label: _("Municipality of Legal Residence"),
                validation: Yup.string().required(_("Municipality of legal residence is required")),
            },
            {
                id: "fineImpositionDate",
                label: _("Date of Fine Imposition"),
                type: "date",
                validation: Yup.date()
                    .required(_("Date of fine imposition is required"))
                    .typeError(_("Invalid date format")),
            },
        ],
    },
    {
        title: "Gas Payment",
        data: [
            {
                id: "cashierId",
                label: _("Cashier ID"),
                validation: Yup.string().required(_("Cashier ID is required")),
            },
        ],
    },
    {
        title: "Online Payment",
        data: [
            {
                id: "telebancaCardPin",
                label: _("Telebanca Card PIN"),
                validation: Yup.string()
                    .matches(/^\d{4}$/, _("PIN must be a 4-digit number"))
                    .required(_("Telebanca card PIN is required")),
            },
            {
                id: "paymentConfirmationToPhone",
                label: _("Payment Confirmation to Phone?"),
                bool: true,
                validation: Yup.bool().notRequired(),
            },
            {
                id: "phoneToConfirm",
                label: _("Phone Number to Confirm"),
                condition: { field: "paymentConfirmationToPhone", value: [true] },
                validation: Yup.string()
                    .matches(/^\d{8}$/, _("Phone number must be an 8-digit number"))
                    .when("paymentConfirmationToPhone", {
                        is: true,
                        then: () => Yup.string().required(_("Phone number to confirm is required")),
                        otherwise: () => Yup.string().notRequired(),
                    }),
            },
            {
                id: "transactionNumber",
                label: _("Transaction Number"),
                number: true,
                validation: Yup.string().required(_("Transaction number is required")),
            },
            {
                id: "amount",
                label: _("Amount"),
                number: true,
                validation: Yup.number()
                    .positive(_("Amount must be a positive number"))
                    .required(_("Amount is required")),
            },
        ],
    },
    {
        title: "Payment via tax vector (RC-04A)",
        data: [
            {
                id: "RC05",
                label: _("RC05"),
                number: true,
                validation: Yup.number().required(_("RC05 is required")),
            },
            {
                id: "RC04A",
                label: _("RC04A"),
                number: true,
                validation: Yup.number().required(_("RC04A is required")),
            },
            {
                id: "amountInCUP",
                label: _("Amount in CUP"),
                number: true,
                validation: Yup.number().required(_("Amount in CUP is required")),
            },
        ],
    },
    {
        title: "Payment by NIT or identity card",
        data: [
            {
                id: "NIT",
                label: _("NIT"),
                number: true,
                validation: Yup.number().required(_("NIT is required")),
            },
            {
                id: "province",
                label: _("Province"),
                select: true,
                options: provinces,
                validation: Yup.string().required(_("Province is required")),
            },
            {
                id: "municipality",
                label: _("Municipality"),
                validation: Yup.string().required(_("Municipality is required")),
            },
            {
                id: "taxes",
                label: _("Taxes"),
                select: true,
                options: [
                    { id: "0114022", name: "0114022" },
                    { id: "0200822", name: "0200822" },
                    { id: "0201022", name: "0201022" },
                    { id: "0510122", name: "0510122" },
                ],
                validation: Yup.string().required(_("Taxes are required")),
            },
            {
                id: "obligationPeriod",
                label: _("Obligation Period"),
                type: "date",
                validation: Yup.date().required(_("Obligation period is required")),
            },
            {
                id: "amountInCUP",
                label: _("Amount in CUP"),
                number: true,
                validation: Yup.number().required(_("Amount in CUP is required")),
            },
        ],
    },
    {
        title: "Stamp seals",
        data: [
            {
                id: "identityCard",
                label: _("Identity Card"),
                number: true,
                validation: Yup.number().required(_("Identity card is required")),
            },
            {
                id: "province",
                label: _("Province"),
                select: true,
                options: provinces,
                validation: Yup.string().required(_("Province is required")),
            },
            {
                id: "municipality",
                label: _("Municipality"),
                validation: Yup.string().required(_("Municipality is required")),
            },
            {
                id: "entity",
                label: _("Entity"),
                select: true,
                options: [
                    { id: "MININTProcedures", name: _("MININT Procedures") },
                    { id: "otherProcedures", name: _("Other Procedures") },
                ],
                validation: Yup.string().required(_("Entity is required")),
            },
            {
                id: "denominations",
                label: _("Denominations"),
                select: true,
                options: [
                    { id: "5", name: _("5 CUP Stamp") },
                    { id: "10", name: _("10 CUP Stamp") },
                    { id: "15", name: _("15 CUP Stamp") },
                    { id: "20", name: _("20 CUP Stamp") },
                    { id: "25", name: _("25 CUP Stamp") },
                    { id: "30", name: _("30 CUP Stamp") },
                    { id: "40", name: _("40 CUP Stamp") },
                ],
                condition: {
                    field: "otherDenominations",
                    value: [false, undefined],
                },
                validation: Yup.string().when("otherDenominations", {
                    is: (otherDenominations) => !otherDenominations,
                    then: () => Yup.string().required(_("Denomination is required")),
                    otherwise: () => Yup.string().notRequired(),
                }),
            },
            {
                id: "otherDenominations",
                label: _("Insert Other Denominations"),
                bool: true,
                validation: Yup.bool().notRequired(),
            },
            {
                id: "amountInCUP",
                label: _("Amount in CUP"),
                number: true,
                condition: { field: "otherDenominations", value: [true] },
                validation: Yup.number().when("otherDenominations", {
                    is: (otherDenominations) => otherDenominations,
                    then: () => Yup.number().required(_("Amount in CUP is required")),
                    otherwise: () => Yup.number().notRequired(),
                }),
            },
        ],
    },
];
