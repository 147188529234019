/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

import _ from "i18n/translate";
import { cards } from "./data";
import AddCardModal from "components/MDModal/AddCardModal";
import { formatCardNumberHidden } from "hooks/formatCardNumber";
import { bankName } from "hooks/getBankName";
import { Box } from "@mui/material";

function PaymentMethod() {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const [openAddCardModal, setOpenAddCardModal] = useState(false);
    const [cardToEdit, setCardToEdit] = useState(null);

    const handleEditCard = (card) => {
        setCardToEdit(card);
        setOpenAddCardModal(true);
    };

    return (
        <>
            <Card id="payment-method" sx={{ py: 3 }}>
                <MDBox
                    pt={2}
                    px={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <MDTypography variant="h6" fontWeight="medium">
                        {_("Payment Method")}
                    </MDTypography>
                    <MDButton
                        variant="gradient"
                        color="dark"
                        onClick={() => {
                            setCardToEdit(null);
                            setOpenAddCardModal(true);
                        }}
                    >
                        <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                        &nbsp;{_("add new card")}
                    </MDButton>
                </MDBox>
                <MDBox p={2}>
                    <Grid container spacing={3}>
                        {cards.map((card) => (
                            <Grid item xs={12} md={6} key={card.id}>
                                <MDBox
                                    borderRadius="lg"
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    sx={{
                                        border: ({ borders: { borderWidth, borderColor } }) =>
                                            `${borderWidth[1]} solid ${borderColor}`,
                                        p: 3,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-around",
                                            flexDirection: { xs: "column", md: "row" },
                                        }}
                                    >
                                        <MDTypography variant="body2" sx={{ mr: 2 }}>
                                            {bankName(card.cardOrAccount)}
                                        </MDTypography>
                                        <MDTypography variant="h6" fontWeight="medium">
                                            {formatCardNumberHidden(card.cardOrAccount)}
                                        </MDTypography>
                                    </Box>
                                    <MDBox
                                        ml="auto"
                                        lineHeight={0}
                                        color={darkMode ? "white" : "dark"}
                                    >
                                        <Tooltip title="Edit Card" placement="top">
                                            <Icon
                                                sx={{ cursor: "pointer" }}
                                                fontSize="small"
                                                onClick={() => handleEditCard(card)}
                                            >
                                                edit
                                            </Icon>
                                        </Tooltip>
                                    </MDBox>
                                </MDBox>
                            </Grid>
                        ))}
                    </Grid>
                </MDBox>
            </Card>
            <AddCardModal
                open={openAddCardModal}
                onClose={() => setOpenAddCardModal(false)}
                cardToEdit={cardToEdit}
            />
        </>
    );
}

export default PaymentMethod;
