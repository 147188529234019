import React from "react";
import PropTypes from "prop-types";
import { Modal, Box, Typography, Stack } from "@mui/material";
import _ from "i18n/translate";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

const labelsMap = {
    date: "Date",
    payment_code: "Payment Code",
    operation: "Operation",
    service: "Service",
    amount: "Amount",
    currency: "Currency",
    origin_card: "Origin",
    destination_card: "Destination",
};

const PaymentDetailsModal = ({ open, onClose, paymentDetails }) => {
    const paymentDetailsArray = Object.entries(paymentDetails);

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: { xs: "90%", md: 400 },
                    p: 3,
                    borderRadius: 2,
                    border: "none",
                    bgcolor: "#fff",
                }}
            >
                <MDBox
                    mx={2}
                    mt={-7}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                >
                    <MDTypography variant="h6" color="white" textAlign="center">
                        {_("Payment Details")}
                    </MDTypography>
                </MDBox>

                <Box sx={{ mt: 3, ml: 1, p: 1, borderRadius: 2 }}>
                    {paymentDetailsArray.map(([key, value], index) => (
                        <Stack
                            key={index}
                            direction="row"
                            spacing={2}
                            sx={{ mb: 2, alignItems: "center" }}
                        >
                            <Typography
                                variant="body1"
                                fontWeight="bold"
                                sx={{ fontSize: { xs: ".9rem", md: "1rem" } }}
                            >
                                {labelsMap[key] || key}:
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ fontSize: { xs: ".9rem", md: "1rem" } }}
                            >
                                {value}
                            </Typography>
                        </Stack>
                    ))}
                </Box>
                <MDButton variant="gradient" color="info" fullWidth type="submit" onClick={onClose}>
                    {_("Accept")}
                </MDButton>
            </Box>
        </Modal>
    );
};

PaymentDetailsModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    paymentDetails: PropTypes.object.isRequired,
};

export default PaymentDetailsModal;
