export const cards = [
    { id: 1, alias: "Casa", cardOrAccount: "5845129965827852", currency: "cup", phone: "58954785" },
    {
        id: 2,
        alias: "Escuela",
        cardOrAccount: "1922959865825248",
        currency: "usd",
        phone: "5698547",
    },
];
