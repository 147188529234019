export default function data() {
    return {
        rows: [
            {
                action: "Transfer",
                date: "2024-10-10T12:30:00",
                bank: "BANMET",
                money: 500.0,
                positive: true,
            },
            {
                action: "Electricity Payment",
                date: "2024-10-10T12:30:00",
                bank: "BANDEC",
                money: 250.0,
                positive: false,
            },
            {
                action: "Water Payment",
                date: "2024-10-09T12:30:00",
                bank: "BANDEC",
                money: 250.0,
                positive: false,
            },
            {
                action: "Transfer",
                date: "2024-09-20T12:30:00",
                bank: "BANMET",
                money: 500.0,
                positive: true,
            },
        ],
    };
}
