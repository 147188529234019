import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import _ from "i18n/translate";
import MDButton from "components/MDButton";
import CreateAppModal from "components/MDModal/CreateAppModal";
import { Box, Button, Card, IconButton } from "@mui/material";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import { BugReport, Cloud, Delete } from "@mui/icons-material";
import Column from "./components/Column";
import { appsDataProduction, appsDataSandbox, columns } from "./data";
import { useSnackbar } from "notistack";
import ConfirmationModal from "components/MDModal/ConfirmationModal";

const ConfigurationApps = () => {
    const [openCreateAppModal, setOpenCreateAppModal] = useState(false);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [appToDelete, setAppToDelete] = useState(null);
    const [sandbox, setSandbox] = useState(true);
    const [data, setData] = useState(appsDataSandbox);
    const { enqueueSnackbar } = useSnackbar();

    const handleConfirmDelete = () => {
        enqueueSnackbar(_("Will be removed: " + appToDelete), { variant: "success" });
        setOpenConfirmModal(false);
    };

    useEffect(() => {
        setData(sandbox ? appsDataSandbox : appsDataProduction);
    }, [sandbox]);

    const rows = data.map((app) => ({
        app: (
            <Column appName={app.appName} clientId={app.clientId} clientSecret={app.clientSecret} />
        ),
        delete: (
            <IconButton
                onClick={() => {
                    setAppToDelete(app.appName);
                    setOpenConfirmModal(true);
                }}
                aria-label="delete"
            >
                <Delete color="error" />
            </IconButton>
        ),
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox
                py={6}
                sx={{
                    minHeight: "100dvh",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Card sx={{ width: { xs: "100%", md: "80%", lg: "70%" }, height: "100%" }}>
                    <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                    >
                        <MDTypography
                            sx={{
                                fontSize: { xs: "1.1rem", md: "1.2rem" },
                                fontWeight: 600,
                                color: "#fff",
                            }}
                        >
                            REST API apps
                        </MDTypography>
                        <MDTypography
                            sx={{ fontSize: { xs: ".8rem", md: ".9rem" }, color: "#fff" }}
                        >
                            {_(
                                "Create an application in a sandbox environment. This will provide you with a Client ID and Client Secret for your tests."
                            )}
                        </MDTypography>
                    </MDBox>
                    <MDBox sx={{ px: { xs: 0, md: 2 } }}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                mt: 4,
                                mb: 3,
                            }}
                        >
                            <MDButton
                                variant="gradient"
                                color="info"
                                sx={{ ml: 3 }}
                                onClick={() => setOpenCreateAppModal(true)}
                            >
                                {_("Create APP")}
                            </MDButton>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                    width: "auto",
                                    bgcolor: "rgba(0,0,0,0.08)",
                                    height: { xs: 60, sm: 30 },
                                    width: { xs: 110, sm: "auto" },
                                    py: 0,
                                    px: 0.3,
                                    mr: 3,
                                    borderRadius: 2,
                                }}
                            >
                                <Button
                                    sx={{
                                        px: 1,
                                        py: 0.5,
                                        minHeight: "auto",
                                        width: { xs: "100%", sm: "auto" },
                                        color: "#2C3C58 !important",
                                        bgcolor: sandbox ? "#fff" : "transparent",
                                        "&:hover": {
                                            bgcolor: sandbox ? "#fff" : "transparent",
                                        },
                                    }}
                                    onClick={() => setSandbox(true)} // Cambiar estado
                                >
                                    <BugReport sx={{ fontSize: 16, mr: 0.5 }} />
                                    {_("Sandbox")}
                                </Button>
                                <Button
                                    sx={{
                                        px: 1,
                                        py: 0.5,
                                        minHeight: "auto",
                                        color: "#2C3C58 !important",
                                        bgcolor: !sandbox ? "#fff" : "transparent",
                                        "&:hover": {
                                            bgcolor: !sandbox ? "#fff" : "transparent",
                                        },
                                    }}
                                    onClick={() => setSandbox(false)} // Cambiar estado
                                >
                                    <Cloud sx={{ fontSize: 16, mr: 0.5 }} />
                                    {_("Production")}
                                </Button>
                            </Box>
                        </Box>
                        <DataTable
                            table={{ columns, rows }}
                            isSorted={false}
                            entriesPerPage={false}
                            showTotalEntries={false}
                            noEndBorder
                        />
                    </MDBox>
                </Card>
            </MDBox>

            <CreateAppModal
                open={openCreateAppModal}
                onClose={() => setOpenCreateAppModal(false)}
                sandbox={sandbox}
            />

            <ConfirmationModal
                open={openConfirmModal}
                onClose={() => setOpenConfirmModal(false)}
                onConfirm={handleConfirmDelete}
                title={_("Confirm Delete")}
                message={_("Are you sure you want to delete this app?")}
            />

            <Footer />
        </DashboardLayout>
    );
};

export default ConfigurationApps;
