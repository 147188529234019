export const chartData = {
    labels: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul"],
    datasets: [
        {
            label: "Transfermovil",
            data: [30, 20, 50, 40, 70, 60, 80],
            color: "info",
        },
        {
            label: "Enzona",
            data: [50, 30, 60, 20, 90, 70, 100],
            color: "success",
        },
        {
            label: "Fincimex",
            data: [70, 40, 80, 60, 100, 90, 120],
            color: "warning",
        },
    ],
};

export const chartData2 = {
    labels: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul"],
    datasets: [
        {
            label: "Successful transactions",
            data: [20, 40, 60, 50, 80, 70, 90],
            color: "info",
        },
        {
            label: "Failed transactions",
            data: [0, 5, 3, 7, 4, 3, 0],
            color: "success",
        },
    ],
};
