/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
// import { Provider } from "react-redux";
// import { PersistGate } from "redux-persist/integration/react";
import App from "App";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ContextProvider } from "./context/provider";
import { SecurityProvider } from "security";

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
    <BrowserRouter>
        <MaterialUIControllerProvider>
            <HelmetProvider>
                <ContextProvider>
                    <SecurityProvider>
                        {/* 
            <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}> */}
                        <Helmet>
                            <title>X Pay</title>
                        </Helmet>
                        <App />
                        {/* </PersistGate>
          </Provider> */}
                    </SecurityProvider>
                </ContextProvider>
            </HelmetProvider>
        </MaterialUIControllerProvider>
    </BrowserRouter>
);
