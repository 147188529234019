import { Card, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import React from "react";
import transactionseData from "./data";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import _ from "i18n/translate";
import { useBillingData } from "layouts/billing/hooks/useBillingData";

const Transactions = () => {
    const { columns, rows } = transactionseData();

    const { dataRows } = useBillingData(rows);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={5}>
                <Card>
                    <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                    >
                        <MDTypography variant="h6" color="white">
                            {_("Transactions")}
                        </MDTypography>
                    </MDBox>
                    <MDBox mt={2} sx={{ px: { xs: 0, md: 2 } }}>
                        <DataTable
                            table={{ columns, rows: dataRows }}
                            isSorted={true}
                            canSearch={true}
                            entriesPerPage={{
                                defaultValue: 10,
                                entries: [],
                            }}
                            showTotalEntries={true}
                            noEndBorder
                        />
                    </MDBox>
                </Card>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
};

export default Transactions;
