import React from "react";
import PropTypes from "prop-types";
import { Modal, Box, Typography } from "@mui/material";
import MDButton from "components/MDButton";

const ConfirmationModal = ({ open, onClose, onConfirm, title, message }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    width: { xs: "90%", md: 400 },
                    m: "auto",
                    mt: "20%",
                    p: 3,
                    borderRadius: 2,
                    bgcolor: "#fff",
                }}
            >
                <Typography variant="h6" sx={{ mb: 2 }}>
                    {title}
                </Typography>
                <Typography sx={{ mb: 3, fontSize: "1rem" }}>{message}</Typography>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <MDButton variant="outlined" color="info" onClick={onClose}>
                        Cancel
                    </MDButton>
                    <MDButton variant="contained" color="error" onClick={onConfirm} sx={{ ml: 2 }}>
                        Confirm
                    </MDButton>
                </Box>
            </Box>
        </Modal>
    );
};

ConfirmationModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string,
    message: PropTypes.string,
};

export default ConfirmationModal;
