export default function projectsTableData() {
    const columns = [
        { Header: "Date", accessor: "date", align: "center" },
        { Header: "Payment code", accessor: "payment_code", align: "center" },
        { Header: "Operation", accessor: "operation", align: "center" },
        { Header: "Service", accessor: "service", align: "center" },
        { Header: "Amount", accessor: "amount", align: "center" },
        { Header: "Currency", accessor: "currency", align: "center" },
    ];

    const rows = [
        {
            date: "2024-10-01",
            payment_code: "PMT123456",
            operation: "Online payment",
            service: "Fincimex",
            amount: 500,
            currency: "USD",
            origin_card: "9224123412341234",
            destination_card: "1234123412341234",
        },
        {
            date: "2024-09-25",
            payment_code: "PMT6582321",
            operation: "Transaction",
            service: "Transfermovil",
            amount: 300,
            currency: "EUR",
            origin_card: "9224123412345678",
            destination_card: "1234123456781234",
        },
        {
            date: "2024-09-20",
            payment_code: "PMT9879654",
            operation: "Online payment",
            service: "Enzona",
            amount: 150,
            currency: "CUP",
            origin_card: "9224987654321987",
            destination_card: "1234987654321987",
        },
        {
            date: "2024-10-01",
            payment_code: "PMT1223456",
            operation: "Transaction",
            service: "Enzona",
            amount: 500,
            currency: "USD",
            origin_card: "9224123412345678",
            destination_card: "1234123412341234",
        },
        {
            date: "2024-09-25",
            payment_code: "PMT6543521",
            operation: "Transaction",
            service: "Transfermovil",
            amount: 300,
            currency: "EUR",
            origin_card: "9224765432109876",
            destination_card: "1234987654321234",
        },
        {
            date: "2024-09-20",
            payment_code: "PMT9837654",
            operation: "Online payment",
            service: "Enzona",
            amount: 150,
            currency: "CUP",
            origin_card: "9224432109876123",
            destination_card: "1234432109876123",
        },
        {
            date: "2024-10-01",
            payment_code: "PMT1230456",
            operation: "Electricity payment",
            service: "Enzona",
            amount: 500,
            currency: "USD",
            origin_card: "9224987654321987",
            destination_card: "1234765432101234",
        },
        {
            date: "2024-09-25",
            payment_code: "PMT2654321",
            operation: "ONAT",
            service: "Transfermovil",
            amount: 300,
            currency: "EUR",
            origin_card: "9224765432109876",
            destination_card: "1234987654321234",
        },
        {
            date: "2024-09-20",
            payment_code: "PMT9870654",
            operation: "Electricity payment",
            service: "Enzona",
            amount: 150,
            currency: "CUP",
            origin_card: "9224432109876123",
            destination_card: "1234432109876123",
        },
        {
            date: "2024-10-01",
            payment_code: "PMT1253456",
            operation: "ONAT",
            service: "Enzona",
            amount: 500,
            currency: "USD",
            origin_card: "9224123412341234",
            destination_card: "1234123412341234",
        },
    ];

    return { columns, rows };
}
