/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useBillingData } from "./hooks/useBillingData";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import DataTable from "examples/Tables/DataTable";
import PaymentMethod from "layouts/billing/components/PaymentMethod";
import PaymentDetailsModal from "components/MDModal/PaymentDetailsModal";
import { Card, Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import { notificationItems } from "./data";
import { statisticsData } from "./data/statisticsData";
import { useEffect, useState } from "react";
import { masterCards } from "./data/masterCards";
import { bankName } from "hooks/getBankName";
import SelectMasterCardModal from "components/MDModal/SelectMasterCardModal";
import Operations from "./components/Operations";
import projectsTableData from "./data/projectsTableData";

function Billing() {
    const { columns, rows } = projectsTableData();

    const { dataRows, selectedRow, openPaymentDetailsModal, handleRowSelect, handleModalClose } =
        useBillingData(rows);

    const [masterCard, setMasterCard] = useState(null);
    const [openSelectMasterCardModal, setOpenSelectMasterCardModal] = useState(false);
    const [openOperationModal, setOpenOperationModal] = useState(false);

    const handleNotificationClick = (item) => {
        if (item.title.toLowerCase().includes("change")) {
            setOpenSelectMasterCardModal(true);
        } else if (item.title.toLowerCase().includes("operation")) {
            setOpenOperationModal(true);
        }
    };

    useEffect(() => {
        if (masterCards.length > 0) {
            setMasterCard(masterCards[0]);
        }
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox mt={8}>
                <MDBox mb={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} xl={6}>
                                    <MasterCard
                                        number={masterCard?.number}
                                        holder={masterCard?.holder}
                                        expires={masterCard?.expires}
                                        bank={bankName(masterCard?.number)}
                                        items={notificationItems}
                                        onNotificationClick={handleNotificationClick}
                                    />
                                    <SelectMasterCardModal
                                        open={openSelectMasterCardModal}
                                        onClose={() => setOpenSelectMasterCardModal(false)}
                                        masterCards={masterCards}
                                        changeCard={setMasterCard}
                                    />
                                    <Operations
                                        open={openOperationModal}
                                        onClose={() => setOpenOperationModal(false)}
                                    />
                                </Grid>
                                {statisticsData.map((stat, index) => (
                                    <Grid item xs={12} md={6} xl={3} key={index}>
                                        <MDBox mb={1.5} sx={{ height: "100%" }}>
                                            <DefaultInfoCard
                                                icon={stat.icon}
                                                title={stat.title}
                                                value={stat.count}
                                                big={true}
                                            />
                                        </MDBox>
                                    </Grid>
                                ))}
                                <Grid item xs={12}>
                                    <PaymentMethod />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MDBox>
                <MDBox mb={3}>
                    <Card>
                        <MDBox
                            mx={2}
                            mt={-3}
                            py={3}
                            px={2}
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="info"
                        >
                            <MDTypography variant="h6" color="white">
                                Payment Details
                            </MDTypography>
                        </MDBox>
                        <MDBox pt={3}>
                            <DataTable
                                table={{ columns, rows: dataRows }}
                                isSorted={false}
                                entriesPerPage={false}
                                showTotalEntries={true}
                                onSelect={handleRowSelect}
                                noEndBorder
                            />
                            <PaymentDetailsModal
                                open={openPaymentDetailsModal}
                                onClose={handleModalClose}
                                paymentDetails={selectedRow ? selectedRow : {}}
                            />
                        </MDBox>
                    </Card>
                </MDBox>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Billing;
