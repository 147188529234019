import { useState, useMemo, createContext, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useGeneralContext } from "context/provider/hooks";
import { isTokenAliveQuery, authMutation } from "../graphql";

const keycloakUrl = "http://localhost:8080";
const oidRealm = "xpay";
const clientId = "xpay";
const configurationUrl = `${keycloakUrl}/realms/${oidRealm}/.well-known/openid-configuration`;

const SecurityContext = createContext({
    bearerToken: undefined,
});

function base64URLEncode(str) {
    return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
        .toString("base64")
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=/g, "");
}

base64URLEncode.propTypes = {
    str: PropTypes.string.isRequired,
};

async function generateCodeVerifierAndChallenge() {
    const codeVerifier = base64URLEncode(crypto.getRandomValues(new Uint8Array(32)));
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await crypto.subtle.digest("SHA-256", data);
    const codeChallenge = base64URLEncode(new Uint8Array(digest));
    return { codeVerifier, codeChallenge };
}

export function useSecurity() {
    const context = useContext(SecurityContext);
    if (context === undefined) {
        throw new Error("invalid hook usage please use it inside a provider");
    }
    return context;
}

function useKeycloak() {
    return useMemo(async () => {
        let bearerToken = sessionStorage.getItem("bearerToken");
        // if there was a bearer token tested for validity, if can be instrospected and is active then the token is considered active.
        if (bearerToken) {
            if (await isTokenAliveQuery()) {
                return {
                    bearerToken,
                };
            }
        }
        // At this point let's handle whether or not we have the code query params and if it is valid or not...
        try {
            const code = new URLSearchParams(location.href).get("code");
            if (code) {
                try {
                    const bearerToken = (await authMutation({ code })).login?.bearerToken;
                    if (!bearerToken) {
                        location.assign(new URL(location.pathname, location.origin));
                    }
                    sessionStorage.setItem("bearerToken", bearerToken); // store the bearer token in the sessionStorage for persistent usage
                    return {
                        bearerToken: sessionStorage.getItem("bearerToken"),
                    };
                } catch (err) {
                    console.error(err);
                }
            }
            const configuration = await (await fetch(configurationUrl)).json();
            const { codeChallenge, codeVerifier } = await generateCodeVerifierAndChallenge();
            localStorage.setItem("pkce_code_verifier", codeVerifier);
            const state = base64URLEncode(crypto.getRandomValues(new Uint8Array(16)));
            const nonce = base64URLEncode(crypto.getRandomValues(new Uint8Array(16)));
            const authorizationUrl = new URL(configuration.authorization_endpoint);
            authorizationUrl.searchParams.append("client_id", clientId);
            authorizationUrl.searchParams.append("response_mode", "query");
            authorizationUrl.searchParams.append("response_type", "code");
            authorizationUrl.searchParams.append("scope", "openid profile");
            authorizationUrl.searchParams.append(
                "redirect_uri",
                new URL(location.pathname, location.origin)
            );
            authorizationUrl.searchParams.append("state", state);
            authorizationUrl.searchParams.append("nonce", nonce);
            authorizationUrl.searchParams.append("code_challenge", codeChallenge);
            authorizationUrl.searchParams.append("code_challenge_method", "S256");
            // try to obtain a new one code by redirecting to the oidc server and entering the user credentials
            location.assign(authorizationUrl);
        } catch (e) {
            console.error(e);
        }
        return {
            bearerToken: bearerToken,
        };
    }, []);
}

export function SecurityProvider({ children }) {
    const keycloak = useKeycloak();
    const [bearerToken, setBearerToken] = useState();
    useEffect(() => {
        keycloak.then((v) => setBearerToken(v));
    }, [keycloak]);
    return (
        <SecurityContext.Provider value={{ bearerToken: bearerToken }}>
            {children}
        </SecurityContext.Provider>
    );
}

SecurityProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default function Guardian({ children }) {
    const { user } = useGeneralContext();

    if (user) {
        return children;
    }

    return children;
}

Guardian.propTypes = {
    children: PropTypes.node.isRequired,
};
