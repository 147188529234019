import React from "react";
import PropTypes from "prop-types";
import { Modal, Box, Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import MDBox from "components/MDBox";
import _ from "i18n/translate";
import { currency, fields } from "./data";
import CustomTextField from "components/MDInput/CustomTextField";
import MDTypography from "components/MDTypography";
import useFieldFormik from "hooks/useFieldFormik";

const AddCardModal = ({ open, onClose, cardToEdit }) => {
    const { enqueueSnackbar } = useSnackbar();

    const formik = useFormik({
        initialValues: {
            alias: cardToEdit ? cardToEdit.alias : "",
            cardOrAccount: cardToEdit ? cardToEdit.cardOrAccount : "",
            currency: cardToEdit ? cardToEdit.currency : "",
            phone: cardToEdit ? cardToEdit.phone : "",
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            alias: Yup.string().required(_("Alias is required")),
            cardOrAccount: Yup.string()
                .matches(
                    /^(03|05|06|12)\d{14}$|^\d{4}(?:1299|9598|0699)\d{8}$/,
                    _("It is NOT a valid account or card.")
                )
                .required(_("Account Number is required")),
            currency: Yup.string().required(_("Currency is required")),
            phone: Yup.string()
                .required(_("Phone number is required"))
                .matches(/^(\+\d{1,3})?\s?\d{7,14}$/, _("Phone number is not valid")),
        }),
        onSubmit: async (values) => {
            try {
                if (cardToEdit) {
                    enqueueSnackbar(_("Card updated successfully"), { variant: "success" });
                } else {
                    enqueueSnackbar(_("New card created"), { variant: "success" });
                }
                onClose();
            } catch (error) {
                enqueueSnackbar(
                    cardToEdit ? _("Error updating card") : _("Error creating new card"),
                    { variant: "error" }
                );
            }
        },
    });

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: { xs: "90%", md: 400 },
                    p: 3,
                    borderRadius: 2,
                    border: "none",
                    bgcolor: "#fff",
                }}
            >
                <MDBox
                    mx={2}
                    mt={-7}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                >
                    <MDTypography variant="h6" color="white" textAlign="center">
                        {cardToEdit ? _("Edit Card") : _("New Card")}
                    </MDTypography>
                </MDBox>
                <Formik
                    initialValues={formik.initialValues}
                    validationSchema={formik.validationSchema}
                    onSubmit={formik.handleSubmit}
                >
                    {({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <MDBox my={3}>
                                {fields.map((field) => (
                                    <CustomTextField
                                        key={field.id}
                                        id={field.id}
                                        label={field.label}
                                        formik={useFieldFormik(formik, field.id)}
                                        select={field.select}
                                        options={field.select ? currency : []}
                                        card={field.label.toLowerCase().includes("card")}
                                        phone={field.label.toLowerCase().includes("phone")}
                                    />
                                ))}
                            </MDBox>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginTop: 2,
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    onClick={onClose}
                                    sx={{
                                        color: "#348DED",
                                        "&:hover": { border: "1px solid #348DED" },
                                    }}
                                >
                                    {_("Close")}
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={formik.isSubmitting}
                                    sx={{ bgcolor: "info", color: "#fff", ml: 2 }}
                                >
                                    {formik.isSubmitting ? _("Saving...") : _("Save")}
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>
        </Modal>
    );
};

AddCardModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    cardToEdit: PropTypes.object,
};

export default AddCardModal;
