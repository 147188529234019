import React, { useMemo } from "react";
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import { Typography } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import TransactionRow from "./components/TransactionRow";

// Data
import data from "./data";

import _ from "i18n/translate";
import { useNavigate } from "react-router-dom";

const TransactionGroup = ({ group, title }) => {
    if (group.length === 0) return null;

    return (
        <>
            <Typography>{_(title)}</Typography>
            {group.map((transaction, index) => (
                <TransactionRow key={index} transaction={transaction} />
            ))}
        </>
    );
};

TransactionGroup.propTypes = {
    group: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
};

const Transactions = () => {
    const { rows } = data();
    const navigate = useNavigate();

    const groupTransactions = (rows) => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        const groups = {
            today: [],
            yesterday: [],
            recent: [],
        };

        rows.forEach((transaction) => {
            const date = new Date(transaction.date);
            if (date.toDateString() === today.toDateString()) {
                groups.today.push(transaction);
            } else if (date.toDateString() === yesterday.toDateString()) {
                groups.yesterday.push(transaction);
            } else {
                groups.recent.push(transaction);
            }
        });

        return groups;
    };

    const groupedTransactions = useMemo(() => groupTransactions(rows), [rows]);

    return (
        <Card sx={{ py: 2, px: { xs: 2, md: 3 }, height: "100%" }}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <MDTypography variant="h6" gutterBottom>
                    {_("Latest transactions")}
                </MDTypography>
                <MDButton
                    variant="outlined"
                    color="info"
                    size="small"
                    onClick={() => navigate("/transactions")}
                >
                    {_("view all")}
                </MDButton>
            </MDBox>
            <MDBox>
                <TransactionGroup group={groupedTransactions.today} title="Today" />
                <TransactionGroup group={groupedTransactions.yesterday} title="Yesterday" />
                <TransactionGroup group={groupedTransactions.recent} title="Recent" />
            </MDBox>
        </Card>
    );
};

export default Transactions;
