export default function transactionData() {
    const columns = [
        { Header: "Transaction Type", accessor: "transaction_type", align: "start" },
        { Header: "Date", accessor: "date", align: "center" },
        { Header: "Service", accessor: "service", align: "center" },
        { Header: "Currency", accessor: "currency", align: "center" },
        { Header: "Amount", accessor: "amount", align: "center" },
    ];

    const rows = [
        {
            transaction_type: "Online Payment",
            date: "2024-10-01",
            service: "Transfermovil",
            currency: "USD",
            amount: 500,
        },
        {
            transaction_type: "Transfer",
            date: "2024-09-25",
            service: "Fincimex",
            currency: "EUR",
            amount: 300,
        },
        {
            transaction_type: "Bill Payment",
            date: "2024-09-20",
            service: "Enzona",
            currency: "CUP",
            amount: 150,
        },
        {
            transaction_type: "Transfer",
            date: "2024-10-01",
            service: "Transfermovil",
            currency: "USD",
            amount: 500,
        },
        {
            transaction_type: "Transfer",
            date: "2024-09-25",
            service: "Fincimex",
            currency: "EUR",
            amount: 300,
        },
        {
            transaction_type: "Online Payment",
            date: "2024-09-20",
            service: "Transfermovil",
            currency: "CUP",
            amount: 150,
        },
        {
            transaction_type: "Electricity Payment",
            date: "2024-10-01",
            service: "Fincimex",
            currency: "USD",
            amount: 500,
        },
        {
            transaction_type: "ONAT Payment",
            date: "2024-09-25",
            service: "Enzona",
            currency: "EUR",
            amount: 300,
        },
        {
            transaction_type: "Electricity Payment",
            date: "2024-09-20",
            service: "Transfermovil",
            currency: "CUP",
            amount: 150,
        },
        {
            transaction_type: "ONAT Payment",
            date: "2024-10-01",
            service: "Fincimex",
            currency: "USD",
            amount: 500,
        },
        {
            transaction_type: "Mobile Payment",
            date: "2024-10-02",
            service: "Enzona",
            currency: "USD",
            amount: 200,
        },
        {
            transaction_type: "Online Purchase",
            date: "2024-09-30",
            service: "Transfermovil",
            currency: "EUR",
            amount: 450,
        },
        {
            transaction_type: "Subscription Payment",
            date: "2024-09-28",
            service: "Enzona",
            currency: "CUP",
            amount: 100,
        },
        {
            transaction_type: "Refund",
            date: "2024-09-27",
            service: "Fincimex",
            currency: "USD",
            amount: 75,
        },
        {
            transaction_type: "Donation",
            date: "2024-09-26",
            service: "Fincimex",
            currency: "EUR",
            amount: 500,
        },
        {
            transaction_type: "Online Payment",
            date: "2024-09-23",
            service: "Transfermovil",
            currency: "USD",
            amount: 350,
        },
        {
            transaction_type: "Insurance Payment",
            date: "2024-09-21",
            service: "Fincimex",
            currency: "CUP",
            amount: 200,
        },
        {
            transaction_type: "Utility Bill",
            date: "2024-09-18",
            service: "Transfermovil",
            currency: "USD",
            amount: 125,
        },
        {
            transaction_type: "Loan Repayment",
            date: "2024-09-15",
            service: "Enzona",
            currency: "EUR",
            amount: 300,
        },
        {
            transaction_type: "Subscription Renewal",
            date: "2024-09-13",
            service: "Transfermovil",
            currency: "CUP",
            amount: 180,
        },
    ];

    return { columns, rows };
}
