import _ from "i18n/translate";

export const columns = [
    { Header: _("App Name"), accessor: "app" },
    { Header: _("Actions"), accessor: "delete", width: "12%" },
];

export const appsDataSandbox = [
    {
        appName: "MercoCuba",
        clientId: "slkc09aslabc3vcdsk",
        clientSecret: "ilsdch87wealfiiucv8icjsc",
    },
    { appName: "PosCaja", clientId: "dhjc63hv34jjkhvk24234" },
    { appName: "Xpay" },
];

export const appsDataProduction = [
    { appName: "MercoCuba" },
    { appName: "PosCaja" },
    { appName: "Xpay" },
];
