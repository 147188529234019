import React from "react";
import PropTypes from "prop-types";
import { Modal, Box, Typography, Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import _ from "i18n/translate";
import CustomTextField from "components/MDInput/CustomTextField";

const CreateAppModal = ({ open, onClose, sandbox = false }) => {
    const { enqueueSnackbar } = useSnackbar();

    const formik = useFormik({
        initialValues: {
            appName: "",
            keyType: "",
            sandbox: sandbox,
        },
        validationSchema: Yup.object({
            appName: Yup.string().required(_("App name is required")),
            keyType: Yup.string().required(_("Key type is required")),
        }),
        onSubmit: async (values) => {
            try {
                enqueueSnackbar(_("Create app with Keycloak"), { variant: "error" });
                onClose();
            } catch (error) {
                enqueueSnackbar(_("Error during app creation"), {
                    variant: "error",
                });
            }
        },
    });

    const fields = [
        { id: "appName", label: _("App Name"), width: "100%", select: false },
        {
            id: "keyType",
            label: _("Key Type"),
            width: "100%",
            select: true,
            options: [
                { id: "public", name: _("Public") },
                { id: "private", name: _("Private") },
            ],
        },
    ];

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    width: { xs: "90%", md: 400 },
                    m: "auto",
                    mt: "20%",
                    p: 3,
                    borderRadius: 2,
                    border: "none",
                    bgcolor: "#fff",
                }}
            >
                <Typography variant="h6">{_("Create new App")}</Typography>
                <Formik
                    initialValues={formik.initialValues}
                    validationSchema={formik.validationSchema}
                    onSubmit={formik.handleSubmit}
                >
                    {({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    gap: 3,
                                    flexWrap: "wrap",
                                }}
                            >
                                {fields.map((field, index) => (
                                    <CustomTextField
                                        key={index}
                                        id={field.id}
                                        label={field.label}
                                        formik={formik}
                                        width={field.width}
                                        select={field.select}
                                        options={field?.options ? field?.options : []}
                                        type={field.type}
                                    />
                                ))}
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginTop: 2,
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    onClick={onClose}
                                    sx={{
                                        color: "#348DED",
                                        "&:hover": { border: "1px solid #348DED" },
                                    }}
                                >
                                    {_("Close")}
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={formik.isSubmitting}
                                    sx={{ bgcolor: "info", color: "#fff", ml: 2 }}
                                >
                                    {formik.isSubmitting ? _("Creating...") : _("Create")}
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>
        </Modal>
    );
};

CreateAppModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    sandbox: PropTypes.bool,
};

export default CreateAppModal;
