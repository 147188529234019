import _ from "i18n/translate";

export const fields = [
    {
        id: "alias",
        label: _("Alias"),
        select: false,
    },
    {
        id: "cardOrAccount",
        label: _("Card or Account"),
        select: false,
    },
    {
        id: "currency",
        label: _("Currency"),
        select: true,
    },
    {
        id: "phone",
        label: _("Phone"),
        select: false,
    },
];

export const currency = [
    { id: "cup", name: _("CUP") },
    { id: "mlc", name: _("MLC") },
    { id: "usd", name: _("USD") },
];
