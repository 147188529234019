export const bankName = (cardNumber) => {
    if (typeof cardNumber !== "string") return "";
    if (!cardNumber) return "";

    const digits = cardNumber.slice(4, 8);
    if (digits === "1299") return "BPA";
    if (digits === "0699") return "BANDEC";
    if (digits === "9598") return "Metropolitano";
    return "Unknown Bank";
};
