import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import OrdersOverview from "../OrdersOverview";

const Column = ({ appName, clientId, clientSecret }) => {
    return (
        <>
            <MDTypography sx={{ fontSize: "1rem" }}>{appName}</MDTypography>
            {(clientId || clientSecret) && (
                <OrdersOverview clientId={clientId} clientSecret={clientSecret} />
            )}
        </>
    );
};

Column.propTypes = {
    appName: PropTypes.string.isRequired,
    clientId: PropTypes.string,
    clientSecret: PropTypes.string,
};

export default Column;
