import React from "react";
import PropTypes from "prop-types";
import { Modal, Box, Typography } from "@mui/material";
import _ from "i18n/translate";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { options } from "./data";

const OnatOptionsModal = ({ open, onClose, changeOption }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: { xs: "95%", md: 500 },
                    p: 3,
                    borderRadius: 2,
                    border: "none",
                    bgcolor: "#fff",
                }}
            >
                <MDBox
                    mx={2}
                    mt={-7}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                >
                    <MDTypography variant="h6" color="white" textAlign="center">
                        {_("Select the payment method")}
                    </MDTypography>
                </MDBox>

                <Box sx={{ mt: 3, p: 1, borderRadius: 2, maxHeight: 500, overflow: "auto" }}>
                    {options.map((option, index) => (
                        <Box
                            key={index}
                            onClick={() => {
                                onClose();
                                changeOption(option);
                            }}
                            sx={{
                                borderRadius: 2,
                                p: 2,
                                mb: 2.5,
                                boxShadow: 2,
                                color: "rgb(70,70,70)",
                                cursor: "pointer",
                                "&:hover": {
                                    boxShadow: 4,
                                },
                            }}
                        >
                            <Typography variant="h6" sx={{ zIndex: 2, color: "inherit" }}>
                                {option.name}
                            </Typography>
                        </Box>
                    ))}
                </Box>

                <MDButton
                    variant="outlined"
                    fullWidth
                    onClick={onClose}
                    sx={{
                        color: "#348DED",
                        "&:hover": { border: "1px solid #348DED" },
                    }}
                >
                    {_("Cancel")}
                </MDButton>
            </Box>
        </Modal>
    );
};

OnatOptionsModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    changeOption: PropTypes.func.isRequired,
};

export default OnatOptionsModal;
